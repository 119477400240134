
<v-row class="my-0">
  <v-col>
    <h3 class="table-title pb-1">Total Usage (workspace total)</h3>
    <v-data-table
      key="1"
      :headers="headers"
      :items="[totalUsageItems]"
      :hide-default-header="true"
      :hide-default-footer="true"
      class="overview-table w-full"
    >
      <template v-slot:header="{ props }">
        <tr class="header-row">
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="header-cell"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr class="row-item">
          <td>{{ item.cpu }}</td>
          <td>{{ item.memory }}</td>
          <td>{{ item.disk }}</td>
          <td>{{ $helper.minutesToReadable(item.estimatedTime) }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
  <v-col>
    <h3 class="table-title pb-1">Node Total Usage</h3>
    <v-data-table
      key="2"
      :headers="headers"
      :items="[totalNodeUsage]"
      :hide-default-header="true"
      :hide-default-footer="true"
      class="overview-table w-full"
    >
      <template v-slot:header="{ props }">
        <tr class="header-row">
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="header-cell"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr class="row-item">
          <td>{{ item.cpu }}</td>
          <td>{{ item.memory }}</td>
          <td>{{ item.disk }}</td>
          <td>{{ $helper.minutesToReadable(item.estimatedTime) }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
  <v-col>
    <h3 class="table-title pb-1">Fargate Total Usage</h3>
    <v-data-table
      key="3"
      :headers="headers"
      :items="[totalFargateUsage]"
      :hide-default-header="true"
      :hide-default-footer="true"
      class="overview-table w-full"
    >
      <template v-slot:header="{ props }">
        <tr class="header-row">
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="header-cell"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr class="row-item">
          <td>{{ item.cpu }}</td>
          <td>{{ item.memory }}</td>
          <td>{{ item.disk }}</td>
          <td>{{ $helper.minutesToReadable(item.estimatedTime) }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
</v-row>
