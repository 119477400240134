
<PageTemplate
  :useCard="false"
  padding="p-0"
  :breadcrumbs="[
    {
      text: 'My RDE',
    },
  ]"
>
  <div class="">
    <TopRdeStatusBar
      :workspaceId="workspaceId"
      :wsInfo="wsInfo"
      :usageInfo="usageInfo"
    />
  </div>
  <div class="m-1 mt-4">
    <WebIdeList
      :items="rdeItems"
      :showFavoriteBtn="true"
      :showRefreshBtn="false"
      :showMeteringBtn="true"
      :viewStateRDE="viewStateRDE"
      @getting-rde-status-error="onRdeStatusError"
    >
      <template v-slot:extraBtn>
        <FButton
          v-if="enableMetering"
          size="md"
          fill
          color="white"
          class="text-black"
          @click="() => handleClickMeteringPricing()"
        >
          Metering
        </FButton>
      </template>
    </WebIdeList>
  </div>
  <div
    :class="{
      'app-rde-popup-detail': true,
      'app-rde-popup-detail-ative': !!selectedApp,
    }"
  >
    <RDELogPopup
      v-if="!!selectedApp"
      :turnOffFunc="() => (selectedApp = null)"
      :selectedApp="selectedApp"
    />
  </div>
</PageTemplate>
