<template>
  <v-row class="my-0">
    <v-col>
      <h3 class="table-title pb-1">Total Usage (workspace total)</h3>
      <v-data-table
        key="1"
        :headers="headers"
        :items="[totalUsageItems]"
        :hide-default-header="true"
        :hide-default-footer="true"
        class="overview-table w-full"
      >
        <template v-slot:header="{ props }">
          <tr class="header-row">
            <th
              v-for="header in props.headers"
              :key="header.text"
              class="header-cell"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <tr class="row-item">
            <td>{{ item.cpu }}</td>
            <td>{{ item.memory }}</td>
            <td>{{ item.disk }}</td>
            <td>{{ $helper.minutesToReadable(item.estimatedTime) }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col>
      <h3 class="table-title pb-1">Node Total Usage</h3>
      <v-data-table
        key="2"
        :headers="headers"
        :items="[totalNodeUsage]"
        :hide-default-header="true"
        :hide-default-footer="true"
        class="overview-table w-full"
      >
        <template v-slot:header="{ props }">
          <tr class="header-row">
            <th
              v-for="header in props.headers"
              :key="header.text"
              class="header-cell"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <tr class="row-item">
            <td>{{ item.cpu }}</td>
            <td>{{ item.memory }}</td>
            <td>{{ item.disk }}</td>
            <td>{{ $helper.minutesToReadable(item.estimatedTime) }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col>
      <h3 class="table-title pb-1">Fargate Total Usage</h3>
      <v-data-table
        key="3"
        :headers="headers"
        :items="[totalFargateUsage]"
        :hide-default-header="true"
        :hide-default-footer="true"
        class="overview-table w-full"
      >
        <template v-slot:header="{ props }">
          <tr class="header-row">
            <th
              v-for="header in props.headers"
              :key="header.text"
              class="header-cell"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <tr class="row-item">
            <td>{{ item.cpu }}</td>
            <td>{{ item.memory }}</td>
            <td>{{ item.disk }}</td>
            <td>{{ $helper.minutesToReadable(item.estimatedTime) }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
const INIT_OBJ = {
  cpu: 0,
  memory: 0,
  disk: 0,
  estimatedTime: 0,
};
export default {
  props: {
    items: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: "",
      headers: [
        { text: "CPU", value: "cpu", sortable: false },
        { text: "Memory", value: "memory", sortable: false },
        { text: "Disk", value: "disk", sortable: false },
        { text: "RDE Estimated Time", value: "estimatedTime", sortable: false },
      ],
      totalUsageItems: { ...INIT_OBJ },
      totalNodeUsage: { ...INIT_OBJ },
      totalFargateUsage: { ...INIT_OBJ },
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) =>
        Object.values(item).some((val) =>
          String(val).toLowerCase().includes(this.searchQuery.toLowerCase()),
        ),
      );
    },
  },
  watch: {
    items: {
      handler() {
        if (this.items.length > 0) {
          this.calculateTotalUsage();
        } else {
          this.resetData();
        }
      },
      deep: true,
    },
  },
  methods: {
    calculateTotalUsage() {
      const totalUsage = { ...INIT_OBJ };
      const totalNodeUsage = { ...INIT_OBJ };
      const totalFargateUsage = { ...INIT_OBJ };

      let totalCpuWeightedSum = 0;
      let totalMemoryWeightedSum = 0;
      let totalDiskWeightedSum = 0;
      let totalNodeCpuWeightedSum = 0;
      let totalNodeMemoryWeightedSum = 0;
      let totalNodeDiskWeightedSum = 0;
      let totalFargateCpuWeightedSum = 0;
      let totalFargateMemoryWeightedSum = 0;
      let totalFargateDiskWeightedSum = 0;

      this.items.forEach((workspaceInfo) => {
        if (workspaceInfo.usedOverview.length > 0) {
          const usageItems = workspaceInfo.usedOverview;
          usageItems.forEach((item) => {
            const cpu = parseFloat(item.cpu);
            const memory = parseFloat(item.memory);
            const disk = parseFloat(item.disk);
            const estimatedTime = parseFloat(item.estimatedTime);

            totalCpuWeightedSum += cpu * estimatedTime;
            totalMemoryWeightedSum += memory * estimatedTime;
            totalDiskWeightedSum += disk * estimatedTime;
            totalUsage.estimatedTime += estimatedTime;

            if (item.instanceType === "node") {
              totalNodeCpuWeightedSum += cpu * estimatedTime;
              totalNodeMemoryWeightedSum += memory * estimatedTime;
              totalNodeDiskWeightedSum += disk * estimatedTime;
              totalNodeUsage.estimatedTime += estimatedTime;
            } else if (item.instanceType === "fargate") {
              totalFargateCpuWeightedSum += cpu * estimatedTime;
              totalFargateMemoryWeightedSum += memory * estimatedTime;
              totalFargateDiskWeightedSum += disk * estimatedTime;
              totalFargateUsage.estimatedTime += estimatedTime;
            }
          });
        }
      });

      totalUsage.cpu = totalUsage.estimatedTime
        ? totalCpuWeightedSum / totalUsage.estimatedTime
        : 0;
      totalUsage.memory = totalUsage.estimatedTime
        ? totalMemoryWeightedSum / totalUsage.estimatedTime
        : 0;
      totalUsage.disk = totalUsage.estimatedTime
        ? totalDiskWeightedSum / totalUsage.estimatedTime
        : 0;

      totalNodeUsage.cpu = totalNodeUsage.estimatedTime
        ? totalNodeCpuWeightedSum / totalNodeUsage.estimatedTime
        : 0;
      totalNodeUsage.memory = totalNodeUsage.estimatedTime
        ? totalNodeMemoryWeightedSum / totalNodeUsage.estimatedTime
        : 0;
      totalNodeUsage.disk = totalNodeUsage.estimatedTime
        ? totalNodeDiskWeightedSum / totalNodeUsage.estimatedTime
        : 0;

      totalFargateUsage.cpu = totalFargateUsage.estimatedTime
        ? totalFargateCpuWeightedSum / totalFargateUsage.estimatedTime
        : 0;
      totalFargateUsage.memory = totalFargateUsage.estimatedTime
        ? totalFargateMemoryWeightedSum / totalFargateUsage.estimatedTime
        : 0;
      totalFargateUsage.disk = totalFargateUsage.estimatedTime
        ? totalFargateDiskWeightedSum / totalFargateUsage.estimatedTime
        : 0;

      this.totalUsageItems = {
        cpu: this.$helper.viewCpuData(totalUsage.cpu),
        memory: this.$helper.viewMemoryData(totalUsage.memory),
        disk: `${this.$helper.roundX(totalUsage.disk, 0)} Gi`,
        estimatedTime: totalUsage.estimatedTime,
      };

      this.totalNodeUsage = {
        cpu: this.$helper.viewCpuData(totalNodeUsage.cpu),
        memory: this.$helper.viewMemoryData(totalNodeUsage.memory),
        disk: `${this.$helper.roundX(totalNodeUsage.disk, 0)} Gi`,
        estimatedTime: totalNodeUsage.estimatedTime,
      };

      this.totalFargateUsage = {
        cpu: this.$helper.viewCpuData(totalFargateUsage.cpu),
        memory: this.$helper.viewMemoryData(totalFargateUsage.memory),
        disk: `${this.$helper.roundX(totalFargateUsage.disk, 0)} Gi`,
        estimatedTime: totalFargateUsage.estimatedTime,
      };
    },
    resetData() {
      this.totalUsageItems = INIT_OBJ;
      this.totalNodeUsage = INIT_OBJ;
      this.totalFargateUsage = INIT_OBJ;
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-table {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.header-cell {
  background-color: #e4e5eb;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.004em;
  text-align: left;
  padding-left: 10px;
}
.search {
  max-width: 200px;
}
.buttons {
  /* color: rgba(19, 19, 22, 1); */
  color: var(--gray2);
  border: 1px solid rgba(19, 19, 22, 0.1) !important;
  &.v-btn__content {
    font-size: 14px;
    font-weight: normal !important;
    line-height: 16.41px;
    letter-spacing: 0.0089em;
    text-align: left;
  }
}
.table-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
}
.overview-table table tbody tr td {
  font-size: 12px !important;
  font-weight: 400;
  letter-spacing: 0.004em;
  text-align: left;
}

.header-row {
  border-radius: 6px;
}
</style>
