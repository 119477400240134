
<div>
  <!-- Row 1: Label and dropdown box -->
  <div class="row my-0">
    <div class="col columns">
      <!-- Label on the left -->
    </div>
    <div class="col columns text-right">
      <!-- Dropdown box on the right -->
      <div class="flex flex-row justify-end items-center">
        <LabelV2 class="mr-2">Date Range:</LabelV2>
        <DateRangePicker
          v-model="dateRange"
          opens="left"
          :genRanges="genRanges"
          :max-duration-start-end="0"
          viewFormat="DD/MM/YYYY"
          class="w-auto"
          :timePicker="false"
        />
      </div>
    </div>
  </div>
  <!-- Row 2: Two multi-select components -->
  <div v-if="!showMyRde" class="row my-0">
    <div class="col py-0 px-0">
      <!-- Left multi-select component -->
      <v-autocomplete
        v-model="selectedWorkspaces"
        :items="workspaceItems"
        placeholder="Select left item"
        class="app-g-autocomplete-v2"
        label="Workspaces"
        item-text="workspaceDisplayName"
        item-value="workspaceId"
        multiple
        outlined
        dense
        chips
        clearable
        deletable-chips
        small-chips
        @change="handleSelectionChange"
      >
        <template v-slot:prepend-item>
          <v-checkbox
            v-model="selectedAll"
            class="select-all-input"
            label="Select All"
            :hide-details="true"
            @change="toggleSelectAll"
          />
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-autocomplete>
    </div>
    <div class="col py-0 px-0">
      <!-- Right multi-select component -->
      <v-autocomplete
        v-model="selectedUsers"
        :items="userItems"
        placeholder="Please select the workspace first"
        class="app-g-autocomplete-v2"
        :label="
          selectedWorkspaces.length === 0
            ? 'Please select the workspace first'
            : 'Users'
        "
        multiple
        outlined
        dense
        chips
        clearable
        deletable-chips
        small-chips
        :disabled="selectedWorkspaces.length === 0"
      />
    </div>
  </div>

  <div class="row flex flex-col gap-2 my-content-wrapper">
    <div class="col py-0 px-0 columns">
      <OverviewTable :loading="loading" :items="workspaceOverviewItems" />

      <!-- Row 3: Table -->
      <div class="mt-4">
        <WorkspaceUsageTable
          :loading="loading"
          :items="workspaceOverviewItems"
        />
      </div>
      <!-- Row 4: Table with pagination -->
      <div v-if="rdeUsageOverview?.length > 0" class="mt-4">
        <RdeUsageTable
          :loading="loading"
          :items="rdeUsageOverview"
          :dateRange="dateRange"
        />
      </div>
    </div>
  </div>
</div>
