<template>
  <div class="p-0 m-0">
    <div class="header">
      <div class="left-header">
        <img
          src="~@/assets/img/arrow-back.svg"
          alt="SVG Icon"
          :style="{ cursor: 'pointer' }"
          @click="goBack"
        />
        <span>{{ mode === "EDIT" ? "Edit RDE" : "Create new RDE" }}</span>
      </div>

      <div class="right-header">
        <div v-if="adminAuth && mode === 'CREATE'" class="switch-v2-group">
          <Sw
            v-model="values.showCreateUser"
            inset
            appendClass="switch-v2"
          ></Sw>
          <span class="text-switch">Create User/Group RDE</span>
        </div>
        <Button :size="'md'" @click="goBack">Cancel</Button>
        <Button :type="'submit'" color="primary" :size="'md'" text fill>
          Apply
        </Button>
      </div>
    </div>
    <div class="rde-section">
      <div class="grid grid-cols-2">
        <Input
          v-model="values.appName"
          label="* RDE Name (12 Characters)"
          appendClass="input-text-field-v2"
          :rules="[
            rules.required,
            rules.name,
            mode !== 'EDIT' ? rules.maxLength(12) : () => true,
          ]"
          :disabled="mode === 'EDIT'"
        />
        <Input
          v-model="values.displayName"
          label="* Display Name"
          appendClass="input-text-field-v2"
          :rules="[rules.required]"
        />
      </div>

      <Input
        v-model="values.description"
        label="Description"
        appendClass="input-text-field-v2"
      />
      <Select
        v-model="values.namespace"
        :items="namespace_items"
        :rules="[rules.required]"
        :disabled="mode === 'EDIT'"
        label="* Namespace"
        appendClass="select-v2"
        :solo="false"
        @input="handleChangeNamespace"
      ></Select>
      <div class="grid grid-cols-2">
        <InfraSelect
          :infraBaselineList="infraBaselineList"
          :values="values"
          :onChangeInfra="(obj) => onChangeInfraRDE(obj, true)"
          :defaultValue="defaultValueInfra"
        />
        <NumberInput
          v-model="values.diskSize.disk"
          label="Disk - GiB"
          :format="'none'"
          appendClass="input-text-field-v2"
          :rules="[rules.required, maxDiskValidate]"
          :disabled="mode === 'EDIT'"
          :max="50"
        />
      </div>
      <div v-if="selectedFargateWs" class="switch-v2-group">
        <!-- *************** -->
        <Tooltip
          v-if="!allowSwitchFaragte"
          text="Instance Type cannot be changed due to <br /> Storage is changed"
          position="right"
          targetSelf
        >
          <Sw
            :value="values.instanceType === 'fargate'"
            inset
            appendClass="switch-v2"
            :disabled="!allowSwitchFaragte"
            @input="toggleFargate"
          ></Sw>
          <span class="text-switch">Fargate</span>
        </Tooltip>
        <!-- ************** -->
        <Fragment v-else>
          <Sw
            :value="values.instanceType === 'fargate'"
            inset
            appendClass="switch-v2"
            @input="toggleFargate"
          ></Sw>
          <span class="text-switch">Fargate</span>
        </Fragment>
      </div>

      <div class="grid grid-cols-2 mt-1">
        <v-autocomplete
          v-if="!fargateMode"
          :value="values.tolerations"
          :items="node_group_items_by_fargate"
          item-text="value"
          :item-value="mapValueNodeGroup"
          label="* Target Node"
          placeholder="Select Target Node"
          class="app-g-autocomplete-v2"
          :menu-props="{ contentClass: 'hide-check-box-auto-complete' }"
          clearable
          outlined
          dense
          :rules="[rules.required]"
          multiple
          @change="onChangeTargetNode"
        />
        <Select
          v-if="!fargateMode"
          v-model="values.diskSize.type"
          :items="storage_type_items"
          :rules="[rules.required]"
          label="*Storage Type"
          appendClass="select-v2"
          :disabled="mode === 'EDIT'"
          :solo="false"
        ></Select>
        <Input
          v-if="fargateMode"
          v-model="values.fargate.key"
          label="* Key"
          appendClass="input-text-field-v2"
          :rules="[rules.required]"
        />
        <Input
          v-if="fargateMode"
          v-model="values.fargate.value"
          label="* Value"
          appendClass="input-text-field-v2"
          :rules="[rules.required]"
        />
      </div>
    </div>
    <div v-if="values.serviceTypes" class="rde-section">
      <div class="rde-title">Service Type</div>
      <div class="service-type">
        <div class="switch-v2-group">
          <Sw
            :value="values.serviceTypes.includes('vscode')"
            inset
            appendClass="switch-v2"
            :disabled="mode === 'EDIT'"
            @input="
              (value) =>
                setServiceTypes({ setValue, values, name: 'vscode', value })
            "
          ></Sw>
          <span class="text-switch">Visual Studio Code</span>
        </div>
        <div class="switch-v2-group">
          <Sw
            :value="values.serviceTypes.includes('webssh')"
            inset
            appendClass="switch-v2"
            :disabled="mode === 'EDIT'"
            @input="
              (value) =>
                setServiceTypes({ setValue, values, name: 'webssh', value })
            "
          ></Sw>
          <span class="text-switch">SSH</span>
        </div>
        <div class="switch-v2-group">
          <Sw
            :value="values.serviceTypes.includes('notebook')"
            inset
            appendClass="switch-v2"
            :disabled="mode === 'EDIT'"
            @input="
              (value) =>
                setServiceTypes({
                  setValue,
                  values,
                  name: 'notebook',
                  value,
                })
            "
          ></Sw>
          <span class="text-switch">Jupyter</span>
        </div>
      </div>
      <div v-if="!values.serviceTypes.length" class="text-xs text-error h-5">
        Require at least one service.
      </div>
    </div>
    <div v-if="values.serviceTypes.includes('vscode')" class="rde-section">
      <div class="rde-title">IDE Type</div>
      <v-btn-toggle
        v-model="values.vscode.ideTypes"
        class="mt-2"
        dense
        variant="outlined"
        color="primary accent-3"
        multiple
        divided
      >
        <v-btn value="web"> Web </v-btn>
        <v-btn value="ssh"> SSH </v-btn>
      </v-btn-toggle>

      <div
        v-if="!values.vscode.ideTypes.length"
        class="text-xs text-error h-5 mt-1"
      >
        Required
      </div>

      <!-- <div v-if="showSshOnlyError" class="text-xs text-error h-5">
        VS Code - SSH only is not supported
      </div> -->
    </div>
    <div class="rde-section">
      <div class="rde-title">Port List</div>
      <AddPortInputListV2
        :portList="values.portList"
        @change="
          (newPorts) => {
            setValue('portList', newPorts);
          }
        "
      />
    </div>
    <div class="rde-section">
      <div class="switch-v2-group gap-2">
        <div class="rde-title">Install Packages</div>
        <Sw
          v-model="values.useInstallPackages"
          inset
          appendClass="switch-v2"
        ></Sw>
      </div>
      <transition name="fade" mode="out-in">
        <InstallPackage
          v-if="values.useInstallPackages === true"
          :values="values"
          :setValue="setValue"
          :installPackages="installPackages"
          :validate="validate"
        />
      </transition>
    </div>
    <div
      v-if="values.serviceTypes && values.serviceTypes.includes('vscode')"
      key="vscode"
      class="rde-section"
    >
      <div class="rde-title">Visual Studio Code Setting</div>
      <VSCodeInput
        :values="values"
        :set-value="setValue"
        :showImageCheckbox="imageVsCodeEnabled"
      />

      <CustomMultiSelectRde
        v-if="values.vscode.useImage && imageVsCodeEnabled"
        key="vscode-image-web"
        v-model="values.vscode.image"
        label="Please select Image"
        :items="images.vscode?.['docker-list']"
        placeholder="Please select Image"
        class="app-g-autocomplete-v2"
        multiple
        outlined
        dense
      />

      <InfraSizeInput
        serviceType="vscode"
        :values="values"
        :requirementInfraResource="guideInfraResource?.requirements"
        :validate="validate"
        @value-change="infraSizeChange"
      />
    </div>

    <div
      v-if="values.serviceTypes && values.serviceTypes.includes('webssh')"
      key="webssh"
      class="rde-section"
    >
      <div class="rde-title">Web SSH Setting</div>
      <Checkbox
        v-if="images?.webssh?.['docker-list'].length > 0"
        v-model="values.webssh.useImage"
        label="Image"
      />
      <CustomMultiSelectRde
        v-if="values.webssh.useImage && imageWebsshEnabled"
        v-model="values.webssh.image"
        label="Please select Image"
        :items="images.webssh?.['docker-list']"
        placeholder="Please select Image"
        class="app-g-autocomplete-v2"
        multiple
        outlined
        dense
      />
      <InfraSizeInput
        serviceType="webssh"
        :values="values"
        :requirementInfraResource="guideInfraResource?.requirements"
        :validate="validate"
        @value-change="infraSizeChange"
      />
    </div>

    <div
      v-if="values.serviceTypes && values.serviceTypes.includes('notebook')"
      key="notebook"
      class="rde-section"
    >
      <div class="rde-title">Jupyter Setting</div>
      <Checkbox
        v-if="images.jupyter?.['docker-list'].length > 0"
        v-model="values.notebook.useImage"
        label="Image"
      />
      <CustomMultiSelectRde
        v-if="
          values.notebook.useImage && images.jupyter?.['docker-list'].length > 0
        "
        v-model="values.notebook.image"
        label="Image"
        :items="images.jupyter?.['docker-list']"
        placeholder="Select Image"
        class="app-g-autocomplete-v2"
        multiple
        outlined
        dense
      />
      <!-- <Checkbox
        v-if="!!images.notebook.length"
        v-model="values.notebook.useImage"
        label="Image"
      />
      <v-autocomplete
        v-if="values.notebook.useImage && !!images.notebook.length"
        v-model="values.notebook.image"
        :items="images.notebook"
        label="Image"
        small-chips
        placeholder="Select Image"
        class="app-g-autocomplete-v2"
        clearable
        outlined
        dense
      /> -->
      <InfraSizeInput
        serviceType="notebook"
        :values="values"
        :requirementInfraResource="guideInfraResource?.requirements"
        :validate="validate"
        @value-change="infraSizeChange"
      />
    </div>

    <div v-if="values.showCreateUser" class="rde-section">
      <div class="rde-title">Create Multiple RDE</div>
      <UserInput
        :values="values"
        :setValue="setValue"
        :projectId="workspace?.projectId"
        mapFields="selectedUsers"
        :accountInfo="accountInfo"
      />
    </div>
  </div>
</template>

<script>
import { RulesMixin } from "@/mixins/RulesMixin";
import { RequestMixin } from "@/mixins/RequestMixin";

import Button from "@/components/atoms/Button/Button.vue";
import Input from "@/components/atoms/Input/Input.vue";
import Sw from "@/components/atoms/Sw/Sw.vue";
import AddPortInputListV2 from "./AddPortInputListV2.vue";
import VSCodeInput from "./VSCodeInput/VSCodeInput.vue";
import { isEmpty } from "lodash";
import InfraSizeInput from "./InfraSizeInput.vue";
import UserInput from "./UserInput.vue";
import {
  getInfraBaseline,
  getInfraByWorkspaceId,
  getInstallPackageByWorkspace,
} from "@/service/apis/workspaceApis";
import InfraSelect from "./InfraSelect.vue";
import Select from "@/components/atoms/Select/Select.vue";
import NumberInput from "@/components/atoms/NumberInput/NumberInput.vue";
import { ProfileAuthMixin } from "@/mixins/ProfileAuthMixin";
import InstallPackage from "./InstallPackage/InstallPackage.vue";
import CommonUIControl from "@/helper/CommonUIControl";
import { mapState } from "vuex";
import CustomMultiSelectRde from "./CustomMultiSelectRde.vue";

const emptyImageObject = {
  "docker-list": [],
};

const initImages = {
  vscode: emptyImageObject,
  webssh: emptyImageObject,
  jupyter: emptyImageObject,
};

const SERVICE_TYPE_ARR = ["vscode", "webssh", "notebook"];

export default {
  props: {
    values: {
      type: Object,
      default: () => {},
    },
    defaultValues: {
      type: Object,
      default: () => {},
    },
    setValue: {
      type: Function,
      default: () => undefined,
    },
    workspace: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "CREATE",
    },
    adminAuth: {
      type: Boolean,
      default: false,
    },
    isSystemAdmin: {
      type: Boolean,
      default: false,
    },
    storage_type_items: {
      type: Array,
      default: () => [],
    },
    validate: {
      type: Function,
      default: () => undefined,
    },
  },
  components: {
    Sw,
    Button,
    Input,
    AddPortInputListV2,
    VSCodeInput,
    InfraSizeInput,
    //PermissionInput,
    UserInput,
    InfraSelect,
    Select,
    NumberInput,
    InstallPackage,
    CustomMultiSelectRde,
  },
  mixins: [RulesMixin, RequestMixin, ProfileAuthMixin],
  data: () => ({
    namespace_items: [],
    infraBaselineList: [],
    node_group_items: [],
    installPackages: {},
    images: initImages,
    selectedFargateWs: null,
    testData: false,
    userSetInfraSize: false,
  }),
  computed: {
    defaultValueInfra() {
      if (
        this.mode === "CREATE" &&
        this.workspace?.defaultSettings?.resourceType
      )
        return this.workspace.defaultSettings.resourceType;

      return this.defaultValues?.resourceSize?.infraId;
    },
    guideInfraResource() {
      if (this.values?.resourceSize?.infraId && this.infraBaselineList?.length)
        return this.infraBaselineList.find(
          (i) => `${i.id}` === `${this.values.resourceSize.infraId}`,
        )?.resourceGuides;

      return {};
    },
    node_group_items_by_fargate() {
      return this.node_group_items.filter((item) => {
        return this.fargateMode
          ? item?.value === "fargate"
          : item?.value !== "fargate";
      });
    },
    fargateMode() {
      return this.values.instanceType === "fargate";
    },
    onlyFargate() {
      return this.workspace.ideWorkspaceTaintList.every(this.isFargate);
    },
    ...mapState({
      accountInfo: (state) => state.accountInfo,
    }),
    allowSwitchFaragte() {
      if (this.mode == "EDIT") {
        if (this.values.instanceType === "fargate") {
          if (this.values.diskSize.type == "file-storage") return true;
          return false;
        } else {
          if (this.values.diskSize.type == "file-storage") return true;
          return false;
        }
      }
      return true;
    },
    imageVsCodeEnabled() {
      return this.images.vscode?.["docker-list"].length > 0;
    },
    imageWebsshEnabled() {
      return this.images.webssh?.["docker-list"].length > 0;
    },
  },
  watch: {
    "values.showCreateUser": {
      immediate: true,
      handler(v) {
        if (v && this.mode === "CREATE" && this.accountInfo?.username) {
          this.setValue("selectedUsers", [this.accountInfo.username]);
        }
      },
    },
    workspace: {
      immediate: true,
      handler(v) {
        this.getDataFromWS(v);
        this.setDefaultRDECreate();
        this.getInstallPackages();
        this.setDefaultRDEFargate();
      },
    },
    "defaultValues.tolerations": {
      immediate: true,
      handler() {
        this.setDefaultGroupNode();
      },
    },
    node_group_items: {
      immediate: true,
      handler() {
        this.setDefaultGroupNode();
      },
    },
    "defaultValues.resourceSize.infraId": {
      immediate: true,
      handler() {
        this.updateLatestResourceSize();
      },
    },
    infraBaselineList: {
      immediate: true,
      handler() {
        this.setDefaultRDECreate();
        this.updateLatestResourceSize();
      },
    },
    "values.namespace": {
      immediate: true,
      handler() {
        this.getInstallPackages();
      },
    },
  },
  methods: {
    setFirstDefaultTolerationNoFargate() {
      const options = this.node_group_items.filter(
        (item) => item?.value !== "fargate",
      );

      if (options.length)
        this.setValue("tolerations", [this.mapValueNodeGroup(options[0])]);
    },
    toggleFargate(value) {
      this.setValue("instanceType", value ? "fargate" : "node");

      if (value) {
        this.setValue("diskSize.type", "file-storage");
        this.setValue("fargate", {
          key: this.selectedFargateWs?.key,
          value: this.selectedFargateWs?.value,
        });
        this.setValue("tolerations", [JSON.stringify(this.selectedFargateWs)]);
      } else {
        // check if default value is available for tolerations and diskSize.type
        if (this.workspace?.defaultSettings.nodeTaint?.length) {
          const nodeTaints = this.workspace?.defaultSettings.nodeTaint
            .split(",")
            .filter((value) => value !== "fargate");
          if (nodeTaints.length) {
            const tolerations = this.workspace?.ideWorkspaceTaintList?.filter(
              (item) => nodeTaints.includes(item?.value),
            );

            this.setValue(
              "tolerations",
              tolerations?.map((i) => this.mapValueNodeGroup(i)),
            );
          } else {
            // pre fill the first value
            this.setFirstDefaultTolerationNoFargate();
          }
        } else {
          // pre fill the first value
          this.setFirstDefaultTolerationNoFargate();
        }

        if (this.mode !== "EDIT") {
          if (this.workspace?.defaultSettings.storageType) {
            this.setValue(
              "diskSize.type",
              this.workspace?.defaultSettings.storageType,
            );
          } else {
            this.setValue("diskSize.type", "");
          }
        }
      }
    },
    isFargate(item) {
      return typeof item === "string"
        ? JSON.parse(item)?.value === "fargate"
        : item?.value === "fargate";
    },
    getInstallPackages() {
      if (this.workspace.id && this.values.namespace) {
        getInstallPackageByWorkspace(this.workspace.id, this.values.namespace)
          .then((res) => {
            this.installPackages = res?.data || {};
          })
          .catch(() => {
            CommonUIControl.ShowErrorToast("Fetch install package failed");
          });
      } else {
        this.installPackages = {};
      }
    },
    goBack() {
      this.$router.back();
    },
    setServiceTypes({ setValue, values, name, value }) {
      let serviceTypes = [];
      if (value) {
        serviceTypes = [name];
      } else {
        serviceTypes = values.serviceTypes.filter((i) => i !== name) || [];
      }
      setValue("serviceTypes", serviceTypes);

      if (value) this.allocateResourceDefault(null, [name]);

      setTimeout(() => {
        this.validate();
      }, 200);
    },
    setDefaultRDECreate() {
      // support auto fill data (namespace, resourceSize ) from workspace
      if (
        this.mode === "CREATE" &&
        this.workspace?.defaultSettings &&
        this.infraBaselineList?.length
      ) {
        if (this.workspace?.defaultSettings.namespace) {
          this.setValue("namespace", this.workspace?.defaultSettings.namespace);
        }

        if (this.workspace?.defaultSettings.resourceType) {
          const selectedObj = this.infraBaselineList.find(
            (i) =>
              Number(i.id) ===
              Number(this.workspace?.defaultSettings.resourceType),
          );

          this.onChangeInfraRDE(selectedObj);
        }
      }
    },
    setDefaultRDEFargate() {
      if (
        this.mode === "CREATE" &&
        this.workspace?.ideWorkspaceTaintList?.length
      ) {
        if (
          this.workspace?.ideWorkspaceTaintList?.every(this.isFargate) ||
          this.workspace?.defaultSettings.nodeTaint?.includes("fargate")
        ) {
          this.setValue("instanceType", "fargate");
          this.setValue("diskSize.type", "file-storage");

          const fargateNodes = this.workspace?.ideWorkspaceTaintList?.filter(
            this.isFargate,
          );

          this.setValue("fargate", {
            key: fargateNodes?.[0]?.key,
            value: fargateNodes?.[0]?.value,
          });

          this.setValue(
            "tolerations",
            fargateNodes?.map((i) => this.mapValueNodeGroup(i)),
          );
        } else {
          this.setValue("instanceType", "node");

          const nodeTaints = this.workspace?.defaultSettings.nodeTaint
            .split(",")
            .filter((value) => value !== "fargate");

          const tolerations = this.workspace?.ideWorkspaceTaintList?.filter(
            (item) => nodeTaints.includes(item?.value),
          );

          if (tolerations.length > 0) {
            this.setValue("tolerations", [
              this.mapValueNodeGroup(tolerations[0]),
            ]);
          } else {
            const selectedItem = this.workspace?.ideWorkspaceTaintList?.filter(
              (item) => item?.value !== "fargate",
            )?.[0];

            if (selectedItem) {
              this.setValue("tolerations", [
                this.mapValueNodeGroup(selectedItem),
              ]);
            }
          }

          if (this.workspace?.defaultSettings.storageType) {
            this.setValue(
              "diskSize.type",
              this.workspace?.defaultSettings.storageType,
            );
          }
        }
      }
    },
    updateLatestResourceSize() {
      if (
        this.infraBaselineList?.length &&
        this.defaultValues?.resourceSize?.infraId
      ) {
        const selectedObj = this.infraBaselineList.find(
          (i) => `${i.id}` === `${this.defaultValues?.resourceSize?.infraId}`,
        );

        this.setValue("resourceSize", {
          infraId: selectedObj?.id || "",
          cpu: selectedObj?.limitCpu || "",
          memory: selectedObj?.limitMemory || "",
        });
      }
    },
    async getDataFromWS(ws) {
      if (!isEmpty(ws)) {
        this.namespace_items = ws?.namespaces.split(",") || [];
        this.node_group_items = ws?.ideWorkspaceTaintList || [];
        this.selectedFargateWs = ws?.ideWorkspaceTaintList?.find(
          (item) => item?.value === "fargate",
        );

        const images = ws?.images || initImages;
        this.images = images;
      }
    },
    async fetchInfraBaseline() {
      const workspaceId = this.$route.params.workspaceId;
      (this.adminAuth
        ? getInfraBaseline()
        : getInfraByWorkspaceId({ workspaceId: workspaceId })
      ).then((res) => {
        this.infraBaselineList = res.data;
      });
    },
    onChangeInfraRDE(obj, force = false) {
      const selectedObj = {
        infraId: obj?.id || "",
        cpu: obj?.limitCpu || "",
        memory: obj?.limitMemory || "",
      };

      this.setValue("resourceSize", selectedObj);
      if (this.mode === "CREATE") {
        this.allocateResourceDefault(selectedObj, SERVICE_TYPE_ARR, force);
      }

      setTimeout(() => {
        this.validate();
      }, 200);
    },
    maxDiskValidate(v) {
      if (v > 50) "Disk exceeds 50";
      return true;
    },
    allocateResourceDefault(
      selectedResourceSizeParams,
      serviceTypesParams,
      force = false,
    ) {
      if (this.userSetInfraSize) return;
      const selectedId = selectedResourceSizeParams?.infraId;
      const resourceGuides = selectedId
        ? this.infraBaselineList.find((i) => `${i.id}` === `${selectedId}`)
            ?.resourceGuides
        : this.guideInfraResource;

      const serviceTypes = serviceTypesParams || this.values.serviceTypes;

      if (resourceGuides?.recommends && serviceTypes?.length) {
        serviceTypes.forEach((key) => {
          const selectedRecommend = resourceGuides?.recommends?.find(
            (r) => r.serviceType === key,
          );
          if (selectedRecommend) {
            let cpu = parseInt(Number(selectedRecommend?.cpu?.value), 10);
            let cpuUnit = "millicore";

            if (cpu >= 1000) {
              cpu = cpu / 1000;
              cpuUnit = "core";
            }

            let memory = parseInt(Number(selectedRecommend?.memory?.value), 10);
            let memoryUnit = "MiB";

            if (memory >= 1024) {
              memory = memory / 1024;
              memoryUnit = "GiB";
            }

            // verify current value is empty or not
            if (
              !this.values[key]?.resourceSize.cpu ||
              !this.values[key]?.resourceSize.memory ||
              force
            ) {
              this.setValue(`${key}.resourceSize`, {
                cpu,
                cpuUnit,
                memory,
                memoryUnit,
              });
            }
          }
        });
      }
    },
    mapValueNodeGroup(item) {
      return JSON.stringify({
        id: item.id,
        key: item?.key,
        operator: "Equal",
        value: item?.value,
        effect: item?.effect,
      });
    },
    setDefaultGroupNode() {
      if (
        this.defaultValues?.instanceType === "fargate" &&
        this.defaultValues?.tolerations?.length
      ) {
        this.setValue("fargate", {
          key: this.defaultValues?.tolerations[0]?.key,
          value: this.defaultValues?.tolerations[0]?.value,
        });
      } else if (
        this.node_group_items.length &&
        this.defaultValues?.tolerations?.length
      ) {
        const selectedItems =
          this.node_group_items
            ?.filter((item) => {
              return this.defaultValues?.tolerations.some(
                (t) => t?.key === item?.key && t?.value === item?.value,
              );
            })
            ?.map((item) => this.mapValueNodeGroup(item)) || [];
        this.setValue("tolerations", selectedItems);
      }
    },
    handleChangeNamespace() {
      this.setValue("installPackages", {
        aptPackages: "",
        pipPackages: "",
        javaVersion: "",
        nodeVersion: "",
        preflightConfig: {
          configType: "",
          name: "",
        },
        mountedConfigList: [],
        mountedFSList: [],
      });
      this.installPackages = {};
    },
    onChangeTargetNode(v) {
      if (v?.length) {
        this.setValue("tolerations", [v[v.length - 1]]);
      } else {
        this.setValue("tolerations", []);
      }
    },
    infraSizeChange(value) {
      this.userSetInfraSize = true;
      SERVICE_TYPE_ARR.forEach((serviceType) => {
        this.values[serviceType].resourceSize.cpu = value.cpu;
        this.values[serviceType].resourceSize.memory = value.memory;
      });
    },
  },
  beforeMount() {
    this.fetchInfraBaseline(this.isAdmin);
  },
};
</script>

<style lang="scss">
.rde-form {
  .input-text-field-v2 {
    padding: 2px;
    & .v-label {
      top: 10px !important;
    }
    .v-label--active {
      top: 12px !important;
    }
    fieldset {
      background: white !important;
    }
    .v-text-field__details {
      min-height: unset;
      height: fit-content;
      .v-messages {
        min-height: unset;
      }
    }
  }
  .select-v2 {
    padding: 2px;
    & .v-label {
      top: 10px !important;
    }
    .v-label--active {
      top: 12px !important;
    }
    fieldset {
      background: white !important;
    }
    .v-text-field__details {
      min-height: unset;
      height: fit-content;
      .v-messages {
        min-height: unset;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    background-color: #fff;

    .left-header {
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-size: 16px;
        font-weight: 500;
        color: #000000de;
        padding-left: 10px;
      }
    }

    .right-header {
      display: flex;
      align-items: center;
      gap: 10px;
      .text-switch {
        font-size: 16px;
        font-weight: 400;
        color: #00000099;
      }
    }
  }

  .general-input {
    background-color: white;
    margin: 5px;
    padding: 10px;
  }

  .service-type {
    display: flex;
    background: white;
    align-items: center;
    gap: 20px;
    margin-left: 5px;
  }

  .switch-v2-group {
    display: flex;
    align-items: center;

    .switch-v2 {
      .v-input__slot {
        margin-bottom: unset;
        height: 24px;
      }
      .v-messages {
        display: none;
      }
      .v-input--selection-controls__input {
        transform: scale(0.8);
      }
    }
    .text-switch {
      font-size: 14px;
      font-weight: 400;
      color: #00000099;
    }
  }

  .rde-section {
    padding: 10px;
    margin: 5px;
    background-color: white;
    border-radius: 2px;
    .rde-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #343a40;
      background: #3399ff1a;
      padding: 2px 8px;
      margin-bottom: 5px;
      width: fit-content;
      border-radius: 4px;
    }
  }

  .horizontal-line {
    background: #949494;
    height: 1px;
    border: none;
    margin: 10px 0px;
  }

  .add-button {
    border: none;
    background: #f5f5f5;
    font-size: 12px;
    font-weight: 700;
  }

  .rde-autocomplete {
    margin-top: 2px;
    fieldset {
      background-color: white !important;
    }
    .v-select__slot {
      min-height: 35px;
    }
    .v-input__slot {
      margin: 0px;
      margin-bottom: 4px !important;
    }

    &.v-text-field--outlined {
      .v-select__slot {
        .v-label {
          top: 11px !important;
          font-size: 14px;
          z-index: 1;
          background-color: #fff;
        }
      }
    }
    .v-select__selections {
      overflow: auto;
      max-height: unset;
    }
    .v-text-field__details {
      min-height: unset;
      height: fit-content;
      .v-messages {
        min-height: unset;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
