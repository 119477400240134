
<div class="w-full">
  <h3 class="table-title pb-1">Workspace Usage Overview</h3>
  <v-data-table
    :headers="headers"
    :items="items"
    :hide-default-footer="true"
    :hide-default-header="true"
    class="elevation-1 w-full"
    :loading="loading"
  >
    <template v-slot:header="{ props }">
      <tr>
        <th
          v-for="header in props.headers"
          :key="header.text"
          class="header-cell"
        >
          {{ header.text }}
        </th>
      </tr>
    </template>
    <template v-slot:item="{ item, index }">
      <tr
        v-for="(usage, i) in item.usedOverview"
        :key="`usage-${item.workspaceId}-${i}`"
        class="row-item"
      >
        <td
          v-if="i === 0"
          :rowspan="item.usedOverview.length"
          :title="item.workspaceDisplayName"
        >
          {{ item.workspaceDisplayName }}
        </td>
        <td v-if="i === 0" :rowspan="item.usedOverview.length">
          {{ item.rdeCount }}
        </td>
        <td v-if="i === 0" :rowspan="item.usedOverview.length">
          {{ $helper.showDate(item.createdDate) }}
        </td>
        <td v-if="i === 0" :rowspan="item.usedOverview.length">
          {{ $helper.minutesToReadable(item.estimatedTime) }}
        </td>
        <td>{{ usage.instanceType }}</td>
        <td>{{ $helper.viewCpuData(usage.cpu) }}</td>
        <td>{{ $helper.viewMemoryData(usage.memory) }}</td>
        <td>{{ $helper.roundX(usage.disk || 0, 0) }} {{ usage.diskUnit }}</td>
        <td>{{ $helper.minutesToReadable(usage.estimatedTime) }}</td>
      </tr>
      <tr
        v-if="item.usedOverview.length === 0"
        :key="`no-usage-${index}`"
        :class="`row-item ${index}`"
      >
        <td :title="item.workspaceDisplayName">
          {{ $helper.truncatedText(item.workspaceDisplayName, 40) }}
        </td>
        <td>{{ item.rdeCount }}</td>
        <td>{{ $helper.showDate(item.createdDate) }}</td>
        <td>{{ $helper.minutesToReadable(item.estimatedTime) }}</td>
        <td colspan="5" class="text-center color-secondary">No usage data</td>
      </tr>
    </template>
    <template v-slot:no-data>
      <div class="flex justify-center items-center h-20">
        No data available
      </div>
    </template>
  </v-data-table>
</div>
