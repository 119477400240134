
<div class="item-status-wrapper">
  <div
    class="flex flex-row px-2 rounded-lg"
    :style="{ 'background-color': bgColor }"
  >
    <div
      class="status-text flex flex-col items-center justify-center"
      :style="{ color: btColor }"
    >
      {{ this.status }}
    </div>
  </div>
</div>
