<template>
  <div class="w-full">
    <h3 class="table-title pb-1">Workspace Usage Overview</h3>
    <v-data-table
      :headers="headers"
      :items="items"
      :hide-default-footer="true"
      :hide-default-header="true"
      class="elevation-1 w-full"
      :loading="loading"
    >
      <template v-slot:header="{ props }">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="header-cell"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item, index }">
        <tr
          v-for="(usage, i) in item.usedOverview"
          :key="`usage-${item.workspaceId}-${i}`"
          class="row-item"
        >
          <td
            v-if="i === 0"
            :rowspan="item.usedOverview.length"
            :title="item.workspaceDisplayName"
          >
            {{ item.workspaceDisplayName }}
          </td>
          <td v-if="i === 0" :rowspan="item.usedOverview.length">
            {{ item.rdeCount }}
          </td>
          <td v-if="i === 0" :rowspan="item.usedOverview.length">
            {{ $helper.showDate(item.createdDate) }}
          </td>
          <td v-if="i === 0" :rowspan="item.usedOverview.length">
            {{ $helper.minutesToReadable(item.estimatedTime) }}
          </td>
          <td>{{ usage.instanceType }}</td>
          <td>{{ $helper.viewCpuData(usage.cpu) }}</td>
          <td>{{ $helper.viewMemoryData(usage.memory) }}</td>
          <td>{{ $helper.roundX(usage.disk || 0, 0) }} {{ usage.diskUnit }}</td>
          <td>{{ $helper.minutesToReadable(usage.estimatedTime) }}</td>
        </tr>
        <tr
          v-if="item.usedOverview.length === 0"
          :key="`no-usage-${index}`"
          :class="`row-item ${index}`"
        >
          <td :title="item.workspaceDisplayName">
            {{ $helper.truncatedText(item.workspaceDisplayName, 40) }}
          </td>
          <td>{{ item.rdeCount }}</td>
          <td>{{ $helper.showDate(item.createdDate) }}</td>
          <td>{{ $helper.minutesToReadable(item.estimatedTime) }}</td>
          <td colspan="5" class="text-center color-secondary">No usage data</td>
        </tr>
      </template>
      <template v-slot:no-data>
        <div class="flex justify-center items-center h-20">
          No data available
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "WorkspaceUsageTable",
  props: {
    items: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Workspace Name",
          value: "workspaceName",
          sortable: false,
        },
        { text: "RDE Count", value: "rdeCount", sortable: false },
        { text: "Created Time", value: "createdDate", sortable: false },
        { text: "Estimated Time", value: "estimatedTime", sortable: false },
        { text: "Instance Type", value: "", sortable: false },
        { text: "CPU", value: "", sortable: false },
        { text: "Memory", value: "", sortable: false },
        { text: "Disk", value: "", sortable: false },
        {
          text: "RDE Estimated Time",
          value: "",
          sortable: false,
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.elevation-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.header-cell {
  background-color: #e4e5eb;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.004em;
  text-align: left;
}

.row-item td {
  font-size: 12px !important;
  font-weight: 400;
  letter-spacing: 0.004em;
  text-align: left;
}
</style>
