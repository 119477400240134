
<Dialog v-model="isOpen" title="History" maxWidth="60vw" maxHeight="80vh">
  <div class="min-h-[500px] overflow-hidden">
    <div v-if="workspaceId" class="w-full">
      <v-autocomplete
        v-model="selectedRdes"
        :items="listRdes"
        label="Select RDEs"
        multiple
        deletable-chips
        small-chips
        clearable
        item-text="rdeDisplayName"
        item-value="rdeId"
        class="app-g-autocomplete-v2 mt-4"
        outlined
        dense
      >
        <v-chip
          color="primary"
          close-icon="mdi-close"
          v-bind="$attrs"
          :input-value="selectedRdes"
          small
        >
        </v-chip>
      </v-autocomplete>
    </div>
    <div v-if="rdeId" class="w-full mt-4">
      <v-text-field
        v-model="search"
        label="Filter"
        outlined
        dense
        clearable
        append-icon="mdi-filter"
        class="app-g-autocomplete-v2 mt-4"
        @click:append="search = ''"
      ></v-text-field>
    </div>
    <div class="table-container">
      <v-data-table
        :headers="filteredHeaders"
        :items="filteredItems"
        hide-default-header
        class="custom-table mt-4"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
      >
        <template v-slot:header="{ props }">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="['custom-header', header.value]"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <tr class="no-border">
            <td v-if="!rdeId">{{ item.rdeName }}</td>
            <td>
              {{ $helper.showDate(item.createdAt) }}
            </td>
            <td>
              {{ item.auditUsername }}
            </td>
            <td>{{ item.instanceType }}</td>
            <td>{{ $helper.viewCpuData(item.cpu) }}</td>
            <td>{{ $helper.viewMemoryData(item.memory) }}</td>
            <td><StatusChip :status="item.status" /></td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
  <div class="flex justify-end gap-2 mt-4">
    <Button color="primary" :size="'md'" text fill @click="isOpen = false">
      Close
    </Button>
  </div>
</Dialog>
