<template>
  <div class="item-status-wrapper">
    <div
      class="flex flex-row px-2 rounded-lg"
      :style="{ 'background-color': bgColor }"
    >
      <div
        class="status-text flex flex-col items-center justify-center"
        :style="{ color: btColor }"
      >
        {{ this.status }}
      </div>
    </div>
  </div>
</template>

<script>
import { POD_STATUS } from "@/service/constants";

const DEFAULT_COLOR = "#768192";

export default {
  props: {
    status: { type: String, default: "Loading" },
  },
  data() {
    return {
      POD_STATUS,
    };
  },
  computed: {
    btColor() {
      switch (this.status?.toLowerCase()) {
        case "running": {
          return "#00A738"; // Green
        }
        case "failed": {
          return "#E55353";
        }
        default:
          return DEFAULT_COLOR;
      }
    },
    bgColor() {
      switch (this.status?.toLowerCase()) {
        case "running": {
          return this.$helper.hex2rgba("#00A738", 0.1); // Green
        }
        case "failed": {
          return this.$helper.hex2rgba("#E55353", 0.1);
        }
        default:
          return this.$helper.hex2rgba(DEFAULT_COLOR, 0.1);
      }
    },
  },
};
</script>

<style type="scss">
.item-status-wrapper {
  flex-basis: content;
  padding: 1px 0;
  width: fit-content;
  .status-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0015em;
    text-align: center;
    min-width: 45px;
  }
}
</style>
