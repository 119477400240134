<template>
  <Dialog v-model="isOpen" title="History" maxWidth="60vw" maxHeight="80vh">
    <div class="min-h-[500px] overflow-hidden">
      <div v-if="workspaceId" class="w-full">
        <v-autocomplete
          v-model="selectedRdes"
          :items="listRdes"
          label="Select RDEs"
          multiple
          deletable-chips
          small-chips
          clearable
          item-text="rdeDisplayName"
          item-value="rdeId"
          class="app-g-autocomplete-v2 mt-4"
          outlined
          dense
        >
          <v-chip
            color="primary"
            close-icon="mdi-close"
            v-bind="$attrs"
            :input-value="selectedRdes"
            small
          >
          </v-chip>
        </v-autocomplete>
      </div>
      <div v-if="rdeId" class="w-full mt-4">
        <v-text-field
          v-model="search"
          label="Filter"
          outlined
          dense
          clearable
          append-icon="mdi-filter"
          class="app-g-autocomplete-v2 mt-4"
          @click:append="search = ''"
        ></v-text-field>
      </div>
      <div class="table-container">
        <v-data-table
          :headers="filteredHeaders"
          :items="filteredItems"
          hide-default-header
          class="custom-table mt-4"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 50, 100],
          }"
        >
          <template v-slot:header="{ props }">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                :class="['custom-header', header.value]"
              >
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr class="no-border">
              <td v-if="!rdeId">{{ item.rdeName }}</td>
              <td>
                {{ $helper.showDate(item.createdAt) }}
              </td>
              <td>
                {{ item.auditUsername }}
              </td>
              <td>{{ item.instanceType }}</td>
              <td>{{ $helper.viewCpuData(item.cpu) }}</td>
              <td>{{ $helper.viewMemoryData(item.memory) }}</td>
              <td><StatusChip :status="item.status" /></td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="flex justify-end gap-2 mt-4">
      <Button color="primary" :size="'md'" text fill @click="isOpen = false">
        Close
      </Button>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "@/components/compositions/Dialog/Dialog.vue";
import { getListRdeIds, getRdeHistory } from "@/service/apis/workspaceApis";
import StatusChip from "./StatusChip.vue";

export default {
  name: "HistoryPopup",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    workspaceId: {
      type: String,
    },
    rdeId: {
      type: String,
    },
  },
  components: {
    Dialog,
    StatusChip,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "RDE Name", value: "rdeName", sort: false },
        { text: "Event time", value: "createdAt" },
        { text: "Actor", value: "auditUsername" },
        { text: "Resource Type", value: "instanceType", sort: false },
        { text: "CPU", value: "cpu" },
        { text: "Memory", value: "memory" },
        { text: "Status", value: "status" },
      ],
      items: [],
      selectedRdes: [],
      listRdes: [],
      rdeIds: [],
      totalItems: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filteredItems() {
      if (this.items && this.items.length > 0) {
        if (!this.search) {
          return this.items;
        }

        return this.items.filter((item) => {
          return Object.values(item).some((value) =>
            String(value).toLowerCase().includes(this.search.toLowerCase()),
          );
        });
      }
      return [];
    },
    filteredHeaders() {
      if (this.rdeId) {
        return this.headers.filter((header) => header.value !== "rdeName");
      }
      return this.headers;
    },
  },
  watch: {
    value(val) {
      this.resetPage();
      if (val) {
        this.getRdeIds();
      }
    },
    options: {
      handler(newVal, oldVal) {
        if (
          (this.rdeId || this.workspaceId) &&
          this.rdeIds?.length > 0 &&
          (newVal.page !== oldVal.page ||
            newVal.itemsPerPage !== oldVal.itemsPerPage)
        ) {
          if (this.selectedRdes.length > 0) {
            this.getRdeHistoryPagination(this.selectedRdes);
          } else {
            this.getRdeHistoryPagination(this.rdeIds);
          }
        }
      },
      deep: true,
    },
    selectedRdes: {
      handler() {
        this.options.page = 1;
        if (this.selectedRdes.length > 0) {
          this.getRdeHistoryPagination(this.selectedRdes);
        } else {
          this.getRdeHistoryPagination(this.rdeIds);
        }
      },
      deep: true,
    },
  },
  methods: {
    async getRdeIds() {
      this.loading = true;
      try {
        if (this.workspaceId) {
          const idsRes = await getListRdeIds(this.workspaceId);
          const resListRde = idsRes?.data?.data;

          if (resListRde && resListRde.length > 0) {
            this.listRdes = resListRde;
            if (this.listRdes.length > 0) {
              this.rdeIds = this.rdeIdsFlatten(this.listRdes);
              await this.getRdeHistoryPagination(this.rdeIds);
            }
          }
        } else if (this.rdeId) {
          this.rdeIds = [this.rdeId];
          await this.getRdeHistoryPagination(this.rdeIds);
        }
      } catch (error) {
        console.log("Error", error);
      } finally {
        this.loading = false;
      }
    },
    async getRdeHistoryPagination(rdeIds) {
      this.loading = true;
      try {
        const { page, itemsPerPage } = this.options;
        const historyReq = {
          rdeIds: rdeIds.toString(),
          page: page,
          perPage: itemsPerPage,
        };

        const historyRes = await getRdeHistory(historyReq);
        if (historyRes?.data?.data) {
          this.items = historyRes.data.data.content;
          this.totalItems = historyRes.data.data.totalElements;
        }
      } catch (error) {
        console.log("Error", error);
      } finally {
        this.loading = false;
      }
    },
    rdeIdsFlatten(rdeObjs) {
      if (rdeObjs.length === 0) {
        return [];
      }
      return rdeObjs.map((rde) => rde.rdeId);
    },
    resetPage() {
      this.options.page = 1;
      this.items = [];
      this.totalItems = 0;
      this.rdeIds = [];
      this.selectedRdes = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.table-container {
  max-height: 70vh;
  overflow-y: auto;
  min-height: 350px;
}

.custom-table {
  border-radius: 6px;

  .v-data-table__wrapper {
    border: 1px solid #e4e5eb !important;
    border-radius: 6px;

    tbody > tr > td {
      color: rgba(0, 0, 0, 0.87);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.004em;
    }
  }

  &.v-data-table-header th {
    background-color: #e4e5eb !important;
    color: rgba(0, 0, 0, 0.6) !important;
  }

  &.v-data-table {
    border-radius: 6px;
  }

  .custom-header {
    background-color: rgba(228, 229, 235, 1) !important;
    color: rgba(0, 0, 0, 0.6) !important;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.004em;
    text-align: left;
  }
  .no-border,
  .no-border td {
    height: 33px;
    border: none !important;
  }
}
</style>
