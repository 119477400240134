
<PageTemplate
  :useCard="false"
  :breadcrumbs="[
    {
      text: 'Metering',
    },
  ]"
  padding="px-8 py-4"
>
  <MeteringPricing />
</PageTemplate>
